@import "moonwalk";
@import "list";
@import "syntax";

.w {
    max-width: 1080px;
}

.rouge-gutter {
    white-space: nowrap;
    width: 0;
}

.custom-table {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    overflow: hidden;
  }
  
  .table-header {
    display: flex;
  }
  
  .table-row {
    display: flex;
  }
  
  .header-item, .row-item {
    flex: 1;
    padding: 2px;
    text-align: left;
  }

  .header-item {
    text-align: center; /* Centering text */
  }
  
  /* This is optional, for horizontal scrolling on smaller screens */
  .table-row {
    overflow-x: auto;
  }
  
  /* Last row-item border fix */
  .table-row:last-child .row-item {
    border-bottom: none;
  }
  